<template>
  <div class="mt-16">
    <!-- FIXME: Change this - Create pages for legal notice and cookies
      <h3 class="title mt-10 mb-4 font-weight-light">{{ $t('about.title') }}</h3>
    <ul>
      <li>
        <a class="link font-weight-light">{{ $t('about.legalNotice') }}</a>
      </li>
      <li>
        <a class="link font-weight-light">{{ $t('about.privacyPolicy') }}</a>
      </li>
      <li>
        <a class="link font-weight-light">{{ $t('about.cookiePolicy') }}</a>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
